// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2021_08-module--afterCaption--de21f";
export var columnWrapper = "maine_digest_2021_08-module--columnWrapper--7ea1a";
export var dateline = "maine_digest_2021_08-module--dateline--7eb8c";
export var heading = "maine_digest_2021_08-module--heading--91ced";
export var heroImage = "maine_digest_2021_08-module--heroImage--cbc86";
export var latestnewsarticleheadline = "maine_digest_2021_08-module--latestnewsarticleheadline--8cfbc";
export var maineDigest = "maine_digest_2021_08-module--maineDigest--070b4";
export var photoCaption = "maine_digest_2021_08-module--photoCaption--37990";
export var sectionHeader = "maine_digest_2021_08-module--sectionHeader--ebf25";
export var subheading = "maine_digest_2021_08-module--subheading--fe2ac";